<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitleContent;" style="background-image: url({{Content.backgroundImage}});">
    <div class="container">
        <div class="page-title-content">
            <h2>{{Content.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{Content.title}}</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content" >
                    <h2>BEST  BUSINESS DIRECTORY  - AUSTRALIAN NATIONAL DIRECTORY </h2>
                    
                    <h5>OUR MISSION</h5>
                    <p >Australia's leading online directory - we cater for both small business and
                        larger businesses . Listing with us will mean clients and customers will
                        find the service they require .</p>

                        <h5>WHO USES BUSINESS DIRECTORIES?</h5>
                        <p>Both Australian businesses understand the importance of being found in a
                            directory as well as the clients or customers who are looking for a service
                            or a product.</p>

                            
                            
                            
                        <p>     Australians are time poor. In Australia, approximately 88% of the population
                            owns a smartphone, thus making Australia one of the foremost adopters of
                             such technology.</p>
                            
                             <p>    We love our food and we love our sport</p>


                </div>
            </div>

            <!-- <div class="col-lg-6 col-md-12">
                <div class="about-image" *ngFor="let Image of aboutImage;">
                    <img [src]="Image.img" alt="image">
                     
                </div>
            </div> -->
        </div>
    </div>


    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">

            <h5>    How much do we use the internet?  </h5>
<p>
As of January 2024, 88.6% of Australian internet users between the ages of
16 and 64 used search engines and web portals in the previous month.</p>

<p> <strong>Here are some other search-related statistics for Australians:</strong></p>

<p> <strong> Local searches </strong></p>
<p>
54% of Australians make local search searches at least once a month, and 12%
search for a local business every day.</p>
 

<h5> Benefits of being listed  in our directory?</h5>

<p>
Clients/Customers looking for a business can be sure of finding a reliable
result tailored to your search criteria</p>

<p>We display a range of businesses which can meet most client needs - whether
you are looking for laser treatment, a local builder, hairdresser and trusty
plumber of electrician.</p>

<p>Join the many Australians looking for a particular service or product.</p>

 
<h5> Helping local businesses grow </h5>
 
<p>Businesses recognise the importance of being seen on the net , where people
expect to find them and where their competitors are.</p>

<h5>Best Business Directory mission statement involves</h5>
 
<p>We  provide both localised and Australia wide advertising  for small  and
larger businesses in Australia. We also offer</p>
 
<ul>
<li>       tailored digital marketing advice</li>
<li>       website creation</li>
    <li>       SEO for business growth</li>
        <li>       social media profiles</li>
</ul>


<h5>WHY CHOOSE US?</h5>
 
<p>At Best Business Directory, we've got over 40 years of combined experience
in the digital marketing online world, and we're proud to be passing the
full benefits of our skills and knowledge onto our customers.</p>

<p>We are a is  small company, so we can guarantee friendly, personal service,
and keep you informed every step of the way as we liaise with our team of
professionals to transform your ideas into reality. We're built on strong
foundations, and we want the same for all our customers.</p>

<p>Our mission is to helps businesses target specific markets and customer
segments.</p>


                </div>
                </div>
                </div>
</section>
<!-- End About Area -->

<!-- <section class="funfacts-area pb-70">
    <app-funfacts></app-funfacts>
</section> -->

<!-- <section class="team-area pt-100 pb-70 bg-f9f9f9">
    <app-team></app-team>
</section> -->

<!-- <section class="how-it-works-area pt-100 pb-70">
    <app-how-it-works></app-how-it-works>
</section>

<div class="partner-area ptb-100 bg-f5f5f5">
    <app-partner></app-partner>
</div>

<section class="feedback-area bg-f9f9f9 ptb-100">
    <app-feedback-style-one></app-feedback-style-one>
</section> -->

<app-footer-style-one></app-footer-style-one>