<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Info</h3>

                    <ul class="link-list">
                        
                        <li><a routerLink="/pricing"><i class="flaticon-left-chevron"></i>Pricing</a></li>
                        <li><a routerLink="/marketing-business-online"><i class="flaticon-left-chevron"></i> About Us</a></li>
                        <li><a routerLink="/contact"><i class="flaticon-left-chevron"></i> Contact Us</a></li>
                        <li><a routerLink="/privacy"><i class="flaticon-left-chevron"></i> Privacy Policy</a></li>
                       
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Top Category</h3>

                    <ul class="link-list">
                        
                        <li><a routerLink="/listings"><i class="flaticon-left-chevron"></i>   Beauty</a></li>
                            <li><a routerLink="/listings"><i class="flaticon-left-chevron"></i>Child Care</a></li>
                                <li><a routerLink="/listings"><i class="flaticon-left-chevron"></i>Dentists</a></li>
                                    <li><a routerLink="/listings"><i class="flaticon-left-chevron"></i>Doctors</a></li>
                                       
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Top Category </h3>

                    <ul class="link-list">
                       
                                <li><a routerLink="/listings"><i class="flaticon-left-chevron"></i>Doctors</a></li>
                                    <li><a routerLink="/listings"><i class="flaticon-left-chevron"></i>Electrician</a></li>
                                        <li><a routerLink="/listings"><i class="flaticon-left-chevron"></i>Fitness</a></li>
                                            <li><a routerLink="/listings"><i class="flaticon-left-chevron"></i>Plumber</a></li>
                       
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>

                    <ul class="footer-contact-info">
                        <li><i class="bx bx-map"></i>Elgin Street Rockhampton 4700</li>
                        <li><i class="bx bx-phone-call"></i><a href="tel:+0488701953">0488701953</a></li>
                        <li><i class="bx bx-envelope"></i><a href="mailto:enquiries@bestbusinessdirectory.com.au"> enquiries&#64;bestbusinessdirectory.com.au </a></li>
                       
                    </ul>

                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <p>©Copyright 2024 Best Business Directory All Rights Reserved </p>
        </div>
    </div>

    <div class="footer-image text-center">
        <img src="assets/img/footer-image.png" alt="image">
    </div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>