import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: 'BROWSE BUSINESS BY CATEGORY',
            paragraph: 'Most popular categories listed below.'
        }
    ]
    singleCategoryBox = [
        {
            icon: 'bx bx-building-house',
            title: 'Builders',
            numberOfPlaces: '16 Places',
            link: 'listings'
        },
        {
            icon: 'flaticon-cleansing',
            title: 'Beauty',
            numberOfPlaces: '42 Places',
            link: 'listings'
        },
        {
            icon: 'bx bx-shield-plus',
            title: 'Child Care',
            numberOfPlaces: '11 Places',
            link: 'listings'
        },
        {
            icon: 'flaticon-heart-1',
            title: 'Dentists',
            numberOfPlaces: '24 Places',
            link: 'listings'
        },
        {
            icon: 'bx bx-clinic',
            title: 'Doctors',
            numberOfPlaces: '8 Places',
            link: 'listings'
        },
        {
            icon: 'flaticon-plumber',
            title: 'Electrical',
            numberOfPlaces: '12 Places',
            link: 'listings'
        },
        {
          
            icon: 'flaticon-exercise',
            title: 'Fitness',
            numberOfPlaces: '16 Places',
            link: 'listings'
        },
        
        {
            icon: 'flaticon-save',
            title: 'Lawyers',
            numberOfPlaces: '10 Places',
            link: 'listings'
        },
        {
            icon: 'flaticon-plumber',
            title: 'Mechanic',
            numberOfPlaces: '25 Places',
            link: 'listings'
        },
        {
            icon: 'bx bx-plus-medical',
            title: 'Optometrist',
            numberOfPlaces: '5 Places',
            link: 'listings'
        },
        {
            icon: 'flaticon-hotel',
            title: 'Restaurants',
            numberOfPlaces: '8 Places',
            link: 'listings'
        },
        {
            icon: 'flaticon-more-1',
            title: 'More',
            link: 'listings'
        }
    ]

}