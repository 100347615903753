<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-bg" *ngFor="let Content of pageTitleContent;">
    <!-- <div class="container">
        <h2>{{Content.title}}</h2>
        <form>
            <div class="row m-0 align-items-center">
                <div class="col-lg-4 col-md-12 p-0">
                    <div class="form-group">
                        <label><i class="flaticon-search"></i></label>
                        <input type="text" class="form-control" placeholder="What are you looking for?">
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="form-group">
                        <label><i class="flaticon-pin"></i></label>
                        <input type="text" class="form-control" placeholder="Location">
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="form-group category-select">
                        <label><i class="flaticon-category"></i></label>
                        <ngx-select-dropdown  formControlName="category" id="category" [(ngModel)]="category" tabindex="0" [multiple]="false" [(ngModel)]="category" [config]="config" [options]="listcategory"></ngx-select-dropdown>
                       
                    </div>
                </div>

                <div class="col-lg-2 col-md-12 p-0">
                    <div class="submit-btn">
                        <button type="submit">Search Now</button>
                    </div>
                </div>
            </div>
        </form>
    </div> -->
</div>
<!-- End Page Title Area -->

<!-- Start Listings Area -->
<section class="listings-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <aside class="listings-widget-area">
                    <!-- <section class="widget widget_filters">
                        <h3 class="widget-title">Filters</h3>

                        <ul>
                            <li><button type="button">$</button></li>
                            <li><button type="button">$$</button></li>
                            <li><button type="button">$$$</button></li>
                            <li><button type="button">$$$$</button></li>
                        </ul>
                    </section> -->

                    <section class="widget widget_categories">

                     <a href="https://www.webdesignsrockhampton.com.au/contact-us.php" target="_blank">   <img src="assets/img/rocky.png" alt="image"> </a>
                          <!-- <h3 class="widget-title">Categories</h3> -->

                  
                    </section>
 

               
                </aside>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="listings-grid-sorting row align-items-center">
                    <!-- <div class="col-lg-5 col-md-6 result-count">
                        <p>We found <span class="count">9</span> listings available for you</p>
                    </div> -->

                    <!-- <div class="col-lg-7 col-md-6 ordering">
                        <div class="d-flex justify-content-end">
                            <div class="select-box">
                                <label>Sort By:</label>
                                <ngx-select-dropdown (searchChange)="searchChange($event)" name="ordering-select" tabindex="0" [multiple]="false" [(ngModel)]="singleSelect" [config]="config" [options]="options2"></ngx-select-dropdown>
                            </div>
                        </div>
                    </div> -->
                </div>

                <div class="row">
                    <div class="col-lg-12 col-md-12" *ngFor="let Content of listDetails | paginate: { itemsPerPage: 20, currentPage: verticalListings }">
                        <div class="single-listings-item">
                            <div class="row m-0">
                                <div class="col-lg-4 col-md-4 p-0">
                                    <span *ngIf="Content.image_link!=''">

                               
                                    <div class="listings-image" style="background-image: url(assets/bimages/{{Content.image_link}});">
                                        <a (click)="get_listing(Content.id,Content.service_name)" class="link-btn" style="cursor: pointer;"> 
                                              <img src="assets/bimages/{{Content.image_link}}" alt="">
                                        <!-- <a routerLink="/{{Content.bookmarkLink}}" class="bookmark-save"><i class="flaticon-heart"></i></a>
                                        <a routerLink="/{{Content.categoryLink}}" class="category"><i class="flaticon-cooking"></i></a> -->
                                     </a>
                                        <!-- <div class="author">
                                            <div class="d-flex align-items-center">
                                                <img [src]="Content.authorImg" alt="image">
                                                <span>{{Content.authorName}}</span>
                                            </div>
                                        </div> -->
                                    </div>
                                    </span>
                                <span *ngIf="Content.image_link==''">
                                    <div class="listings-image" style="background-color: #fdaa41; color: #fff;">
                                    <span  style="color: #fff;">
                                    <a (click)="get_listing(Content.id,Content.service_name)" class="link-btn" style="cursor: pointer;color: #fff;padding-top: 60px;
    text-align: center;"> 
                                        <img src="assets/bimages/{{Content.image_link}}" alt="" >
                                        {{Content.service_name}} 
                                 
                               </a>
                               </span>
                            </div>
                                    </span>
                                </div>

                                <div class="col-lg-8 col-md-8 p-0">
                                    <div class="listings-content">
                                        <a (click)="get_listing(Content.id,Content.service_name)"  style="cursor: pointer;"> 
                                        <!-- <span class="status {{Content.extraClass}}"><i class="flaticon-save"></i> {{Content.openORclose}}</span> -->
                                        <h3>{{Content.service_name}}</h3>
                                        <!-- <div class="d-flex align-items-center justify-content-between">
                                            <div class="rating">
                                                <i class='{{Star.icon}}' *ngFor="let Star of Content.rating;"></i>
                                                <span class="count">({{Content.ratingCount}})</span>
                                            </div>
                                            <div class="price">
                                                {{Content.price}}
                                            </div>
                                        </div> -->
                                        <ul class="listings-meta">
                                            <li><i class="bx bx-category"></i><a routerLink="/{{Content.categoryLink}}">  {{Content.service_category}}</a></li>
                                            <li><i class="flaticon-pin"></i> {{Content.city}}</li>
                                        </ul>
                                    </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area text-center">
                            <pagination-controls (pageChange)="verticalListings = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Listings Area -->

<app-footer-style-one></app-footer-style-one>