<!-- Start Listings Area -->
<section class="listings-area ptb-100 bg-f9f9f9">
    <div class="container">
        <div class="section-title" *ngFor="let Title of sectionTitle;">
            <h2>{{Title.title}}</h2>
            <p>{{Title.paragraph}}</p>
        </div>

        <div class="listings-slides">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide *ngFor="let Content of listDetails;">
                    <div class="single-listings-box">
                        <div class="listings-image">
                          
                            <!-- <img src="assets/bimages/{{Content.image_link}}" alt=""> -->
                            
                            <a (click)="get_listing(Content.id,Content.service_name)" class="link-btn" style="cursor: pointer;"></a>
                            <!-- <i class="flaticon-heart"></i> -->
                            <!-- <a (click)="get_listing(Content.id,Content.service_name)" class="link-btn" style="cursor: pointer;"> <i class="flaticon-cooking"></i></a> -->
                        </div>
                        <div class="listings-content">
                            <!-- <div class="author">
                                <div class="d-flex align-items-center">
                                    <img [src]="Content.authorImg" alt="image">
                                    <span>{{Content.authorName}}</span>
                                </div>
                            </div> -->
                            <ul class="listings-meta">
                                  <li><i class="bx bx-category"></i><a routerLink="/{{Content.categoryLink}}">  {{Content.service_category}}</a></li>
                                <li><i class="flaticon-pin"></i> {{Content.city}}</li>
                            </ul>

                            <a (click)="get_listing(Content.id,Content.service_name)" class="link-btn" style="cursor: pointer;"> 
                            <h3><a routerLink="/{{Content.detailsLink}}">{{Content.service_name}}</a></h3>
                            </a>
                            <!-- <span class="status {{Content.extraClass}}"><i class="flaticon-save"></i> {{Content.openORclose}}</span> -->
                            <!-- <div class="d-flex align-items-center justify-content-between">
                                <div class="rating">
                                    <i class='{{Star.icon}}' *ngFor="let Star of Content.rating;"></i>
                                    <span class="count">({{Content.ratingCount}})</span>
                                </div>
                                <div class="price">
                                    {{Content.price}}
                                </div>
                            </div> -->
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>

    <div class="divider2"></div>
</section>
<!-- End Listings Area -->